.form-label {
  font-weight: 700;

  &--light {
    font-weight: $font-weight-base;
  }
}

.form-text {
  display: block;
  margin-bottom: $form-text-margin-bottom;
  font-size: inherit;
  color: $text-muted;
}

.custom-control,
.custom-control-label,
.custom-control-input {
  cursor: pointer;

  &[disabled],
  &.disabled {
    cursor: default;
  }
}

.custom-control-input[disabled]~.custom-control-label {
  cursor: default;
}

.custom-checkbox--checkbox-only {
  padding-left: $custom-checkbox-indicator-size;
}

.custom-checkbox--checkbox {
  z-index: 1;
}

.custom-control-label {
  padding-left: 0; // 0.625rem;

  // adjust alignment of inner checkmark icon
  &:after {
    margin-left: 0.0625rem; // 1px
    margin-top: 0.0625rem; // 1px
  }
}

.custom-control-label--checkbox-only {

  &:before,
  &:after {
    left: -$custom-checkbox-indicator-size;
  }

  &:after {
    // hardcoded value to align the checkmark icon
    left: add(-$custom-checkbox-indicator-size, -0.01rem) !important;
  }
}

.custom-switch {
  min-height: $custom-switch-indicator-size;

  .custom-control-label {
    &::before {
      background-color: $gray-400;
      height: $custom-switch-height;
    }

    &:after {
      top: add(($font-size-base * $line-height-base - $custom-switch-height) / 2, $custom-control-indicator-border-width * 2);
      left: add(-($custom-switch-width + $custom-control-gutter), $custom-switch-border-width * 2);
      background-color: $white;
    }
  }

  .custom-control-input:checked~.custom-control-label {
    &::after {
      transform: translateX($custom-switch-width - $custom-switch-height - $custom-switch-border-width);
    }
  }
}