@import "../../../styles/config";

$Navbar__aside-width: 17.5rem;

.Navbar {

  &__search-input {
    min-width: 23.75rem;
  }

  &__dropdown-toggle-icon {
    &:after {
      content: none;
    }
  }

  &__brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -0.1875rem;
    margin-bottom: -0.1875rem;
  }

  &__picture {
    display: block !important;
    width: 2.625rem !important;
    height: 2.625rem !important;
  }

  &__nav-item-badge {
    position: absolute;
    padding: 0.3rem 0.425rem;
    font-weight: 500;
    border-radius: 0;
    top: 0.25rem;
    right: 0;
  }
}