.ViewProductsList {
    &__container {
        overflow-x: auto;
        display: flex;
    }
}

.ProductCard {
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.certificate-container {
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
}

.certificate-container .price-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
    /* Adjust color as needed */
}