@import "../../../styles/config";

.ProfilePageContent {
  &--grade {
    text-align: center;
    width: $spacer * 16;
  }

  &--unenrol-title {
    text-align: center;
    width: $spacer * 4;
  }

  &--unenrol-button {
    text-align: center;
    width: $spacer * 4;
    padding: $table-cell-remove-button-padding-y $table-cell-padding !important;
  }
}
