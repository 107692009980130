@import "styles/config";

.ChangeEmailConfirmation {
  padding-top: $spacer * 10;
  padding-bottom: $spacer * 10;

  &__card-body {
    padding: ($spacer * 2) ($spacer * 10) !important;
  }

  &__heading {
    font-size: 4.0625rem;
    text-align: center;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 4;
    font-weight: 300;
  }
}
