@import "styles/config";

$Navbar__aside-width: 17.5rem;


$Navbar__sidemenu-padding-top: 1.875rem;
$Navbar__sidemenu-padding-bottom: 1.625rem;
$Navbar__sidemenu-padding-x: $spacer;
$Navbar__navlink-padding-y: $nav-link-padding-y * 1.25;
$Navbar__navlink-padding-left: $nav-link-padding-x * .75;
$Navbar__navlink-padding-right: $nav-link-padding-x;

.NavbarSidemenu {
  &__header-image {
    width: 6.25rem;
    height: 6.25rem;
  }

  &__link {
    text-align: left;
    padding: $nav-link-padding-y * 1.25 $nav-link-padding-x;
    border-radius: $border-radius;

    &,
    &:hover {
      color: $body-color;
    }

    &:hover {
      background: $gray-200;

      &:active {
        background: $gray-300;
      }
    }
  }

  &__link-badge {
    padding: 0.3rem 0.425rem;
    font-weight: 500;
    border-radius: 0;
  }

  &__link-icon {
    font-size: 1.5em;
  }

  &__header {

  }

  &__backdrop {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__aside {
    padding: $Navbar__sidemenu-padding-top $Navbar__sidemenu-padding-x $Navbar__sidemenu-padding-bottom $Navbar__sidemenu-padding-x;
    text-align: center;
    height: 100%;
    min-height: 100%;
    width: 0;
    position: fixed;
    z-index: 10002;
    top: 0;
    left: 0;
    background-color: $white;
    overflow-x: hidden;
    transition: all .5s cubic-bezier(.55, 0, .1, 1);
    transform: translate3d(-280px, 0, 0);
    display: flex;
    flex-direction: column;

    &--open {
      min-width: $Navbar__aside-width;
      width: $Navbar__aside-width;
      height: 100%;
      transform: translate3d(0, 0, 0);
      @include box-shadow("2px 0 15px rgb(0 0 0 / 35%");
    }
  }

  &__divider {
    border-top: 1px solid $gray-400;
    margin: $spacer * .5 0;
    opacity: 1;
  }

  &__footer {
    margin-top: auto;
    width: 100%;
    padding-top: $Navbar__sidemenu-padding-bottom;
  }

  &__footer-image {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.125rem;
  }

  &__footer-caption {
    text-align: center;
    font-size: .75rem;
    font-weight: 600;
    letter-spacing: -.3px;
  }

  &__footer-link {
    &,
    &:hover,
    &:active {
      text-decoration: none;
      color: $body-color;
    }
  }
}
