.badge {
    &--lg {
        font-size: large;
    }

    @each $color, $value in $colors {
        &-#{$color} {
            @include badge-variant($value);
        }
    }
}
