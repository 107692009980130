.table {
  &__button {
    padding: $table-cell-options-btn-padding-y $btn-padding-x-sm;
  }
}

.table-cell {
  &--picture {
    text-align: center;
    padding: $table-cell-picture-padding-y $table-cell-padding !important;
    width: $table-image-size + ($table-cell-padding * 2);
  }

  &--checkbox {
    text-align: center;
    padding: $table-cell-checkbox-padding-y $table-cell-padding !important;
    width: $custom-checkbox-indicator-size + .125rem + ($table-cell-padding * 2);
  }

  &--label-sm {
    text-align: center;
    width: 7.5rem;
  }

  &--options {
    text-align: center;
    width: $spacer * 4;
    padding: $table-cell-options-padding-y $table-cell-padding !important;

    &--header {
      padding: $table-cell-padding !important;
    }
  }

  &--info {
    text-align: center;
    width: $spacer * 8;
    padding: $table-cell-options-padding-y $table-cell-padding !important;

    &--header {
      padding: $table-cell-padding !important;
    }
  }

  &--info-xl {
    text-align: center;
    vertical-align: middle !important;
    width: $spacer * 12;
    padding: $table-cell-options-padding-y $table-cell-padding !important;

    &--header {
      padding: $table-cell-padding !important;
    }
  }
}

.table-row--clickable {
  cursor: pointer;
}
