@import "../../../../styles/config";

.NavbarNotifications {

  &__dropdown-divider {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  &__dropdown-header {
    margin-bottom: 0.5rem;
    padding-left: 1.2rem !important;
    padding-right: 1.3rem !important;
  }

  &__dropdown-item {
    background-color: $gray-100 !important;
    margin-right: 3rem !important;
  }

  &__dropdown-item-read {
    margin-right: 3rem !important;
  }

  &__dropdown-item-none {
    padding-left: 1.2rem !important;
  }

  &__dropdown-item-text {
    padding-left: 0rem !important;
  }

  &__dropdown-menu {
    padding-bottom: 0rem !important;
  }
}
