@import 'styles/config';

$SubNavbar__padding-y: $navbar-padding-y;

.SubNavbar {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

  &.navbar-expand > .container {
    padding-left: $navbar-padding-x;
    padding-right: $navbar-padding-x;
  }

  .dropdown-menu {
    margin-top: $SubNavbar__padding-y !important;
  }
}
