.Select {

    &--dropdown-button {
        width: 60%;
        margin-right: 0.25rem;
    }

    &--confirm-button {
        width: 40%;
        margin-left: 0.25rem;
    }

    &--redirect-to-registration-form {
        width: 30%;
        margin-top: 1.2rem;
        font-size: larger;
    }
}

.Register {
    &--text {
        justify-self: center;
        font-weight: bolder;
        font-size: larger;
    }

    &--school-name {
        width: 50%;
        margin-right: 0.25rem;
    }

    &--timezone {
        width: 50%;
        margin-left: 0.25rem;
    }

    &--configure-domain {
        width: 50%;
        margin-right: 0.25rem;
    }

    &--preview-domain {
        width: 50%;
        margin-left: 0.25rem;
    }

    &--register-button {
        width: 30%;
        font-size: larger;
    }

    &--admin-section-title {
        font-size: 2rem;
        font-weight: bold;
        margin-top: 1rem;
    }
}

.btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.center-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Dropdown-toggle:not(.btn-secondary) {
    color: #8F9393;
    background-color: #FFFFFF !important;
    border-color: #DDDDDD;
    border-width: 1px;
    border-style: solid;
    appearance: none;

    &:hover {
        background-color: #f8f9fa;
        border-color: #adb5bd;
        color: #7A7E7E;
    }

    &:active {
        background-color: #f8f9fa;
        border-color: #adb5bd;
        color: #7A7E7E;
    }
}