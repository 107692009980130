@import "../../../styles/config";

.AlertPage {
  padding: $spacer * 10 0 $spacer * 10;
  text-align: center;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;

  &__title {
    margin-bottom: $spacer;
  }

  &__description {
    font-size: $font-size-base * 1.25;
  }
}
