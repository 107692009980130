@import "styles/config";

@keyframes blink {
  0% {
  }

  50% {
    background-color: rgba(255, 255, 255, .2);
  }

  100% {
  }
}

.ProgressSliderLoading {
  display: inline-block;
  vertical-align: middle;
  padding-right: 3px;

  &__point {
    background-color: rgba(255, 255, 255, 1);
    float: left;
    height: 4px;
    margin-left: 3px;
    width: 4px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    border-radius: 4px;

    &--a {
    }

    &--b {
      animation-delay: .2s;
    }

    &--c {
      animation-delay: .4s;
    }
  }
}
