.Signup {
    &--text {
        justify-self: center;
        font-weight: bolder;
        font-size: larger;
    }

    &--first-name {
        width: 50%;
        margin-right: 0.25rem;
    }

    &--last-name {
        width: 50%;
        margin-left: 0.25rem;
    }

    &--signup-button {
        width: 30%;
        font-size: larger;
    }

    &--teacher {

        &--first-name {
            width: 45%;
            margin-right: 0.25rem;
        }

        &--last-name {
            width: 45%;
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }

        &--title {
            width: 10%;
            margin-left: 0.25rem;
        }
    }
}

.ConfirmEmail {
    &--text {
        justify-self: center;
        font-weight: bolder;
        font-size: larger;
    }

    &--email {
        width: 100%;
        margin-right: 0.25rem;
        justify-self: center;
    }

    &--submit-button {
        width: 60%;
        font-size: larger;
        justify-self: center;
    }
}