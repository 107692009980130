// Large Container Size
.container.container--lg {
  @include media-breakpoint-up(sm, $grid-breakpoints-lg) {
    max-width: 100%;
  }

  @include media-breakpoint-up(md, $grid-breakpoints-lg) {
    max-width: 100%;
  }

  @include media-breakpoint-up(xl, $grid-breakpoints-lg) {
    max-width: map-get($grid-breakpoints-lg, xl);
  }

  @include media-breakpoint-up(xxl, $grid-breakpoints-lg) {
    max-width: map-get($grid-breakpoints-lg, xxl);
  }
}
