@import "styles/config";

.NavbarSearch {
  &__input {
    min-width: 23.75rem;
  }

  &__dropdown-menu {
    margin-top: $spacer * 0.25 !important;
  }

  &__suggestion {
    &:active,
    &:hover,
    &--highlighted {
      background-color: $gray-200 !important;
    }
  }
}