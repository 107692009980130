// Cloudschool-specific variables
//
// Variables that were not originally defined in `_variables.scss`
$navbar-background: #F1F1F1;

// Tables

$table-image-size: 2rem; // 32px
$table-row-inner-height: $font-size-base * $line-height-base;
$table-row-height: $table-row-inner-height + ($table-cell-padding * 2);
$table-cell-picture-padding-y: (($table-row-height - $table-image-size) / 2);
$table-cell-checkbox-padding-y: (
  (($table-row-height - $custom-control-indicator-size) / 2)
);
$table-cell-remove-button-padding-y: (($table-row-height - $table-image-size) / 2);

$table-cell-options-btn-padding-y: $btn-padding-y-sm * .75;
$table-cell-options-btn-size: $table-row-inner-height + ($table-cell-options-btn-padding-y * 2); // + 0.125rem;
$table-cell-options-padding-y: ($table-row-height - $table-cell-options-btn-size) / 2;
