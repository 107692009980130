@import "../../../../styles/config";

.CourseRecordTable {
    &__download {
        width: $spacer * 11;
        text-align: center;
        vertical-align: middle !important;
    }

    &__activity {
        width: $spacer * 8;
        text-align: center;
        vertical-align: middle !important;
    }

    &__status {
        &--title {
            text-align: center;
        }
        &--pass {
            font-size: 0.85rem;
            font-weight: 600;
            color: $success;
            text-align: center;
        }
        &--fail {
            font-size: 0.85rem;
            font-weight: 600;
            color: $danger;
            text-align: center;
        }
        &--pending {
            font-size: 0.85rem;
            font-weight: 600;
            color: $warning;
            text-align: center;
        }
    }
}