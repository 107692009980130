@import "styles/config";

$ProgressSlider__height: 0.3125rem;
$ProgressSlider__bubble-width: 2.8125rem;
$ProgressSlider__bubble-height: 1.25rem;
$ProgressSlider__bubble-width-wide: 6.25rem;
// How much the bubble overhangs the edge of the bar
$ProgressSlider__overhang: 0.3125rem;

.ProgressSlider {
  width: 100%;

  &__bar {
    &.progress {
        height: $ProgressSlider__height;
        overflow: visible;
        padding-right: (($ProgressSlider__bubble-width * 0.5) - $ProgressSlider__overhang);
    }

    &--wide {
      padding-right: ($ProgressSlider__bubble-width-wide * 0.5) - $ProgressSlider__overhang;
    }

    &--wide-centered {
      padding-right: 0;
    }
  }

  &__progress {
    &.progress-bar{
        position: relative;
        border: none;
        min-width: (($ProgressSlider__bubble-width * 0.5) + $ProgressSlider__overhang);
        box-shadow: none;
        overflow: visible;
    }

    &--wide {
      min-width: (($ProgressSlider__bubble-width-wide * 0.5) + $ProgressSlider__overhang);
    }

    &--center {
      padding-right: 0;
    }
  }

  &__bubble {
    white-space: nowrap;
    position: absolute;
    color: $white;
    width: $ProgressSlider__bubble-width;
    top: (-($ProgressSlider__bubble-height - $ProgressSlider__height) * 0.5);
    right: (-$ProgressSlider__bubble-width * 0.5);
    height: $ProgressSlider__bubble-height;
    line-height: $ProgressSlider__bubble-height;
    text-align: center;
    border-radius: ($ProgressSlider__bubble-height * .25);
    cursor: pointer;

    &--wide {
      width: $ProgressSlider__bubble-width-wide;
      right: (-$ProgressSlider__bubble-width-wide * 0.5);
    }

    &--center {
      right: auto;
      left: 50%;
      margin-left: (-$ProgressSlider__bubble-width * 0.5);

      &.ProgressSlider__bubble--wide {
        margin-left: (-$ProgressSlider__bubble-width-wide * 0.5);
      }
    }
  }
}

a.ProgressSlider__bubble {
  &:active,
  &:hover,
  &:focus {
    color: $white !important;
  }
}
