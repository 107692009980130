@import "styles/config";

$FullScreenModal__spacer: $spacer * 3;

$FullScreenModal__font-size: 0.875rem;
$FullScreenModal__line-height: 1.33;
$FullScreenModal__input-padding-x: 1rem;
$FullScreenModal__input-padding-y: 0.625rem;
$FullScreenModal__height: add($FullScreenModal__line-height * $FullScreenModal__font-size, add($FullScreenModal__input-padding-y * 2, 0.125rem, false));

.FullScreenModal {
  background: $gray-150;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-top: $FullScreenModal__spacer * 1.25;

  @media (min-width: breakpoint-max("xs", $grid-breakpoints)) {
    padding-top: $FullScreenModal__spacer;
  }

  &__container {
    &--sm {
      max-width: 23.75rem;
      margin: 0 auto;
    }

    &--md {
      max-width: 38.4375rem;
      margin: 0 auto;
    }

    &--lg {
      max-width: 46.25rem;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: $h2-font-size;
    font-weight: 700;
    margin-bottom: $FullScreenModal__spacer * .5;
    text-align: center;

    @media (min-width: breakpoint-max("xs", $grid-breakpoints)) {
      margin-bottom: $FullScreenModal__spacer;
    }
  }

  &__close-link {
    position: absolute;
    color: $body-color;
    right: $grid-gutter-width / 2;
    top: $grid-gutter-width / 2;

    @media (min-width: breakpoint-min("xs", $grid-breakpoints)) {
      right: $grid-gutter-width * 2;
      top: $grid-gutter-width * 2;
    }

    &:hover,
    &:focus {
      color: $body-color;
    }
  }

  &__close-icon {
    font-size: 2rem;
    height: 2rem;
  }

  &__description {
    color: $gray-600;
    font-size: $FullScreenModal__font-size;
    font-weight: 600;
  }

  &__form-group {
    margin-bottom: 0.625rem !important;
  }

  &__form-control {
    line-height: $FullScreenModal__line-height !important;
    font-size: $FullScreenModal__font-size !important;
    min-height: $FullScreenModal__height !important;
    padding: 0.625rem 1rem !important;
    border: none !important;
    border-radius: 0;
  }

  &__button {
    padding: $FullScreenModal__input-padding-y $FullScreenModal__input-padding-x !important;
    font-size: $FullScreenModal__font-size !important;
  }

  &__control-link {
    color: $gray-600;
    text-decoration: none;
    position: absolute;
    top: 0;
    right: $FullScreenModal__input-padding-x;
    padding: $FullScreenModal__input-padding-y $FullScreenModal__input-padding-x;

    &:hover,
    &:focus {
      color: $gray-700;
      text-decoration: none;
    }
  }
}