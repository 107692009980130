//
// Pills
//

.nav-pills {
  .nav-item {
    margin-bottom: $spacer * .25;
  }
  .nav-link {
    display: flex;
    align-items: center;
    color: $body-color;
    transition: all .2s;

    &:hover {
      color: $white;
      background: $gray-600;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}
