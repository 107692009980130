@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

// Import local variables
@import "config";

// Import the default bootstrap styles
@import "base/bootstrap";

// Components
@import "components/buttons";
@import "components/navbar";
@import "components/tables";
@import "components/forms";
@import "components/badge";
@import "components/page-header";
@import "components/table-controls";
@import "components/type";
@import "components/containers";
@import "components/nav";
@import "components/layout";
@import "components/links";
