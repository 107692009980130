@import "../../../styles/config";

.block {
  width: 100px;
  height: 100px;
}

.bg-gray-100 {
  background: $gray-100;
}

.bg-gray-200 {
  background: $gray-200;
}

.bg-gray-300 {
  background: $gray-300;
}

.bg-gray-400 {
  background: $gray-400;
}

.bg-gray-500 {
  background: $gray-500;
}

.bg-gray-600 {
  background: $gray-600;
}

.bg-gray-700 {
  background: $gray-700;
}

.bg-gray-800 {
  background: $gray-800;
}

.bg-gray-900 {
  background: $gray-900;
}