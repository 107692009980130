@import "styles/config";

.ResetPassword {
  padding-top: $spacer * 10;
  padding-bottom: $spacer * 10;

  &__terms {
    text-align: right;
    margin-top: $spacer * 10;
  }

  &__password-group {
    position: relative;
  }

  &__password-input {
    padding-right: 5rem !important;
  }

  &__forgot-link {
    color: $gray-600;
    text-decoration: none;
    position: absolute;
    top: 0;
    right: $input-padding-x * 1.5;
    padding: $input-padding-y $input-padding-x;

    &:hover,
    &:focus {
      color: $gray-700;
      text-decoration: none;
    }
  }
}