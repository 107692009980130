.RegisterSchoolSuccess {

    &__email {
        color: #007bff; /* Blue color like a link */
        font-weight: bold;
        cursor: pointer;
    }

    &__text {
        font-size: larger;
        text-align: center;
    }
}